import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStylePage = createGlobalStyle`
  @media(min-width: 300px) and (max-width: 767px){
    .img-obra{
      width: 100%;
      height: 222px;
    }
    .speech-bubble-mini{
      margin-top: -345px;
      margin-left: 108px;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .img-obra{
      width: 100%;
      height: 465px;
    }
    .speech-bubble-mini{
      margin-top: -440px;
      margin-left: 238px;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .img-obra{
      width: 100%;
      height: 622px;
    }
    .speech-bubble-mini{
      margin-top: -592px;
      margin-left: 360px;
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .img-obra{
      width: 84%;
    }
    .speech-bubble-mini{
      margin-top: -270px;
      margin-left: 390px;
    }
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .img-obra{
      width: 100%;
    }
    .speech-bubble-mini{
      margin-top: -300px;
      margin-left: 520px;
    }
  }
  @media(min-width: 1920px){
    .img-obra{
      width: 99%;
    }
    .speech-bubble-mini{
      margin-top: -400px;
      margin-left: 720px;
    }
  }
`
export const ContArrow = styled.div`
  width: 100%;
  height: 40px;
  z-index: 999;
`
export const ContImgHombreVolando = styled.div`
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 100px;
    height: auto;
    margin-top: -164px;
    margin-left: 135px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 200px;
    height: auto;
    margin-top: -56px;
    margin-left: 280px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 260px;
    height: auto;
    margin-top: -125px;
    margin-left: 380px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 280px;
    height: auto;
    margin-top: 170px;
    margin-left: 400px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 350px;
    height: auto;
    margin-top: 160px;
    margin-left: 492px;
  }
  @media(min-width: 1920px){
    width: 400px;
    height: auto;
    margin-top: 220px;
    margin-left: 750px;
  }
`
export const ImgHombreVolando = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgMujer2 = styled.div`
  height: auto;
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 50px;
    margin-top: -206px;
    margin-left: 155px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 105px;
    margin-top: -148px;
    margin-left: 350px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 130px;
    margin-top: -210px;
    margin-left: 400px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 130px;
    margin-top: 90px;
    margin-left: 400px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 184px;
    margin-top: 84px;
    margin-left: 500px;
  }
  @media(min-width: 1920px){
    width: 230px;
    margin-top: 170px;
    margin-left: 700px;
  }
`
export const ImgMujer2 = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgTorzo = styled.div`
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 50px;
    height: auto;
    margin-top: -323px;
    margin-left: -40px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 90px;
    height: auto;
    margin-top: -410px;
    margin-left: -95px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 110px;
    height: auto;
    margin-top: -570px;
    margin-left: -120px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 110px;
    height: auto;
    margin-top: -243px;
    margin-left: -120px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 180px;
    height: auto;
    margin-top: -360px;
    margin-left: -145px;
  }
  @media(min-width: 1920px){
    width: 210px;
    height: auto;
    margin-top: -425px;
    margin-left: -188px;
  }
`
export const ImgTorzo = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgMujerBailando = styled.div`
  height: auto;
  animation: image-generated 5s alternate infinite;
  z-index: 99;

  @keyframes image-generated
  {
    0% {
      transform: scaleX(0);
    }
    10% {
      transform: scaleX(-1);
    }
    15% {
      transform: scaleX(0);
    }
    20% {
      transform: scaleX(-1);
    }
    23% {
      transform: scaleX(0);
    }
    30% {
      transform: scaleX(-1);
    }
    40% {
      transform: scaleX(0);
    }
    50% {
      transform: scaleX(-1);
    }
    60% {
      transform: scaleX(0);
    }
    70% {
      transform: scaleX(-1);
    }
    80% {
      transform: scaleX(0);
    }
    90% {
      transform: scaleX(-1);
    }
    100% {
      transform: scaleX(0);
    }
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 60px;
    margin-top: -260px;
    margin-left: -10px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 130px;
    margin-top: -300px;
    margin-left: -29px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 150px;
    margin-top: -360px;
    margin-left: -29px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 150px;
    margin-top: 0px;
    margin-left: -29px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 200px;
    margin-top: 0px;
    margin-left: -29px;
  }
  @media(min-width: 1920px){
    width: 250px;
    margin-top: 0px;
    margin-left: -29px;
  }
`
export const ImgMujerBailando = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgPiernas = styled.div`
  height: auto;
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 80px;
    margin-top: -220px;
    margin-left: -48px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 170px;
    margin-top: -190px;
    margin-left: -100px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 210px;
    margin-top: -250px;
    margin-left: -145px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 210px;
    margin-top: 50px;
    margin-left: -145px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 250px;
    margin-top: 50px;
    margin-left: -190px;
  }
  @media(min-width: 1920px){
    width: 330px;
    margin-top: 70px;
    margin-left: -270px;
  }
`
export const ImgPiernas = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgHombreDeCabeza = styled.div`
  height: auto;
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 30px;
    margin-top: -230px;
    margin-left: -115px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 65px;
    margin-top: -210px;
    margin-left: -240px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 80px;
    margin-top: -285px;
    margin-left: -320px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 80px;
    margin-top: 25px;
    margin-left: -340px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 100px;
    margin-top: 25px;
    margin-left: -448px;
  }
  @media(min-width: 1920px){
    width: 140px;
    margin-top: 25px;
    margin-left: -605px;
  }
`
export const ImgHombreDeCabeza = styled.img`
  width: 100%;
  height: 100%;
`
