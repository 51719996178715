import * as React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import Page05Comp from '../components/Pages/05';
// import {ContBookOpenPagesComp} from '../components/ContBookOpen/openPages';

const Page05 = () => {
  return (
    <Layout
      themeColor={'light'}
      clsOverflow={true}
    >
      <SEO
        title=""
        description=""
        works='georgina quintana'
        bolImage='1'
        ogImage={`cv_f.jpg`}
        twitterImage={`cv_t.jpg`}
      />

      {/* <ContBookOpenPagesComp> */}
        <Page05Comp/>
      {/* </ContBookOpenPagesComp> */}

    </Layout>
  );
};

export default Page05;
