import React, {useEffect, useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Column, Columns} from 'bloomer';
import {ContObraComp} from '../../ContObra';
import {GlobalStylePage, ContArrow, ContImgHombreVolando, ImgHombreVolando, ContImgMujer2, ImgMujer2, ContImgTorzo, ImgTorzo, ContImgMujerBailando, ImgMujerBailando, ContImgPiernas, ImgPiernas, ContImgHombreDeCabeza, ImgHombreDeCabeza} from './styles';
import {ArrowNavTop, ArrowNavRight, ArrowNavBottom, ArrowNavLeft} from '../../ArrowsNavigation';
import {GlobalStyle} from '../../../styles/GlobalStyles';
import {gsap} from "gsap";
import {SpeechBubble} from '../../SpeechBubbles';
import {SpeechBubbleMini} from '../../SpeechBubbles/SpeechBubbleMini';
import imgHombreVolando from '../../../images/obras/obra-5/hombre-volando.png';
import imgMujer2 from '../../../images/obras/obra-5/mujer2.png';
import imgTorzo from '../../../images/obras/obra-5/torzo.png';
import imgMujerBailando from '../../../images/obras/obra-5/mujer-bailando.png';
import imgPiernas from '../../../images/obras/obra-5/piernas.png';
import imgHombreDeCabeza from '../../../images/obras/obra-5/hombre-de-cabeza.png';
import useSound from 'use-sound';
import soundHombrevolando from '../../../images/obras/obra-5/terremoto.mp3';
import {BtnReplay} from '../../BtnReplay';
import {BtnParrilla} from '../../BtnParrilla';

const arNav = {
  top: null,
  right: 6,
  bottom: null,
  left: 4,
};

const Page05Comp = () => {
  const [showArrows, setShowArrows] = useState(false);
  const [initSpeechBubbleMini, setInitSpeechBubbleMini] = useState(false);
  const [initSpeechBubble, setInitSpeechBubble] = useState(false);
  const [initAnimatedMenFly, setInitAnimatedMenFly] = useState(false);
  const [initAnimatedMujer2, setInitAnimatedMujer2] = useState(false);
  const [initAnimatedTorzo, setInitAnimatedTorzo] = useState(false);
  const [initAnimatedMujerBailando, setInitAnimatedMujerBailando] = useState(false);
  const [initAnimatedPiernas, setInitAnimatedPiernas] = useState(false);
  const [initAnimatedHombreDeCabeza, setInitAnimatedHombreDeCabeza] = useState(false);
  const [initSoundMenFly, setInitSoundMenFly] = useState(false);
  const [reLaunchAll, setReLaunchAll] = useState(false);

  /*const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "obras/obra-1/obra.jpeg"}) {
        childImageSharp {
          fixed(width: 340, height: 670) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);*/
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "obras/obra-5/obra.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const mouseOver = () => {
    setShowArrows(true);
  };
  const mouseLeaver = () => {
    setShowArrows(false);
  };
  const fnSpeechBubbleMini = () => {
    return (
      <>
        {
          initSpeechBubbleMini &&
          <SpeechBubbleMini
            id={'speech-bubble-mini'}
            className={'speech-bubble-mini'}
            onClick={() => setInitSpeechBubble(true)}
          />
        }
      </>
    )
  }
  const fnSpeechBubble = () => {
    return (
      <>
        {
          initSpeechBubble &&
          <SpeechBubble
            id={'speech-bubble'}
            className={''}
            classClose={'close-top-left'}
            onClick={() => setInitSpeechBubble(false)}
          >
            <div>Y de una estación de moscas</div>
            <div>y de polvo una tarde juntamos sus pedazos.</div>
          </SpeechBubble>
        }
      </>
    )
  }

  const fnReLaunchAll = () => {
    fnReLaunchHombreVolando(0);
    fnReLaunchMujer2(0);
    fnReLaunchTorzo(0);
    fnReLaunchMujerBailando(0);
    fnReLaunchPiernas(0);
    fnReLaunchHombreDeCabeza(0);
    setReLaunchAll(!reLaunchAll);
  }

  const fnReLaunchHombreVolando = (noReplay = 1) => {
    setInitAnimatedMenFly(false);
    setInitSoundMenFly(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedMenFly(true);
      }, 1000);
    }
  };
  const fnReLaunchMujer2 = (noReplay = 1) => {
    setInitAnimatedMujer2(false);
    // setInitSoundMujer2(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedMujer2(true);
      }, 1000);
    }
  };
  const fnReLaunchTorzo = (noReplay = 1) => {
    setInitAnimatedTorzo(false);
    // setInitSoundTorzo(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedTorzo(true);
      }, 1000);
    }
  };
  const fnReLaunchMujerBailando = (noReplay = 1) => {
    setInitAnimatedMujerBailando(false);
    // setInitSoundMujerBailando(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedMujerBailando(true);
      }, 1000);
    }
  };
  const fnReLaunchPiernas = (noReplay = 1) => {
    setInitAnimatedPiernas(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedPiernas(true);
      }, 1000);
    }
  };
  const fnReLaunchHombreDeCabeza = (noReplay = 1) => {
    setInitAnimatedHombreDeCabeza(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedHombreDeCabeza(true);
      }, 1000);
    }
  };

  const fnAnimateHombreVolando = () => {
    return (
      <ContImgHombreVolando className={'img-animated'} onClick={() => fnReLaunchHombreVolando()}>
        {
          initAnimatedMenFly &&
          <ImgHombreVolando
            id={'animate-hombre-volando'}
            className={'animate-hombre-volando'}
            src={imgHombreVolando}
            alt="Hombre Volando"
          />
        }
      </ContImgHombreVolando>
    )
  }
  const fnAnimateMujer2 = () => {
    return (
      <ContImgMujer2 className={'img-animated'} onClick={() => fnReLaunchMujer2()}>
        {
          initAnimatedMujer2 &&
          <ImgMujer2
            id={'animate-mujer-2'}
            className={'animate-mujer-2'}
            src={imgMujer2}
            alt="Mujer"
          />
        }
      </ContImgMujer2>
    )
  }
  const fnAnimateTorzo = () => {
    return (
      <ContImgTorzo className={'img-animated'} onClick={() => fnReLaunchTorzo()}>
        {
          initAnimatedTorzo &&
          <ImgTorzo
            id={'animate-torzo'}
            className={'animate-torzo'}
            src={imgTorzo}
            alt="Torzo"
          />
        }
      </ContImgTorzo>
    )
  }
  const fnAnimateMujerBailando = () => {
    return (
      <ContImgMujerBailando className={'img-animated'} onClick={() => fnReLaunchMujerBailando()}>
        {
          initAnimatedMujerBailando &&
          <ImgMujerBailando
            id={'animate-mujer-bailando'}
            className={'animate-mujer-bailando'}
            src={imgMujerBailando}
            alt="Mujer Bailando"
          />
        }
      </ContImgMujerBailando>
    )
  }
  const fnAnimatePiernas = () => {
    return (
      <ContImgPiernas className={'img-animated'} onClick={() => fnReLaunchPiernas()}>
        {
          initAnimatedPiernas &&
          <ImgPiernas
            id={'animate-piernas'}
            className={'animate-piernas'}
            src={imgPiernas}
            alt="Piernas"
          />
        }
      </ContImgPiernas>
    )
  }
  const fnAnimateHombreDeCabeza = () => {
    return (
      <ContImgHombreDeCabeza className={'img-animated'} onClick={() => fnReLaunchHombreDeCabeza()}>
        {
          initAnimatedHombreDeCabeza &&
          <ImgHombreDeCabeza
            id={'animate-piernas'}
            className={'animate-piernas'}
            src={imgHombreDeCabeza}
            alt="Hombre de Cabeza"
          />
        }
      </ContImgHombreDeCabeza>
    )
  }

  const [playHombreVolando, { stopHombreVolando }] = useSound(
    soundHombrevolando,
    { volume: 0.3 }
  );

  useEffect(() => {
    setTimeout(function (){
      setInitAnimatedMenFly(true);
    }, 3000);

    setTimeout(function (){
      setInitAnimatedMujer2(true);
    }, 6000);

    setTimeout(function (){
      setInitAnimatedTorzo(true);
    }, 9000);

    setTimeout(function (){
      setInitAnimatedMujerBailando(true);
    }, 12000);

    setTimeout(function (){
      setInitAnimatedPiernas(true);
    }, 12000);

    setTimeout(function (){
      setInitAnimatedHombreDeCabeza(true);
    }, 12000);

    setTimeout(function (){
      setInitSpeechBubbleMini(true);
    }, 15000);
  },[reLaunchAll]);

  useEffect(() => {
    const windowWrap = gsap.utils.wrap(0, window.innerWidth);

    const winWidth = window.innerWidth;
    let arPosMenFly = {};
    let arPosMujer2 = {};

    if(winWidth < 767){
      arPosMenFly = {x: -120, y: -120}
      arPosMujer2 = {x: -50}
    } else if(winWidth < 1023){
      arPosMenFly = {x: -250, y: -250}
      arPosMujer2 = {x: -120}
    } else if(winWidth < 1215){
      arPosMenFly = {x: -300, y: -330}
      arPosMujer2 = {x: -120}
    } else if(winWidth < 1407){
      arPosMenFly = {x: -300, y: -300}
      arPosMujer2 = {x: -120}
    } else if(winWidth < 1919){
      arPosMenFly = {x: -400, y: -400}
      arPosMujer2 = {x: -120}
    } else {
      arPosMenFly = {x: -600, y: -450}
      arPosMujer2 = {x: -120}
    }

    if(initAnimatedMenFly){
      gsap.to(".animate-hombre-volando", {
        x: arPosMenFly.x,
        y: arPosMenFly.y,
        duration: 3,
        delay: 0,
      });

      if(!initSoundMenFly){
        playHombreVolando();
        setInitSoundMenFly(true);
      }
    }

    if(initAnimatedMujer2){
      gsap.to(".animate-mujer-2", {
        x: arPosMujer2.x,
        duration: 3,
        delay: 0,
      });
    }

    if(initAnimatedPiernas){
      gsap.to(".animate-piernas", {
        duration: 3,
        delay: 0,
      });
    }
  }, [initAnimatedMenFly, initAnimatedMujer2, initAnimatedPiernas]);

  return (
    <ContObraComp>
      <BtnParrilla/>
      <BtnReplay onClick={() => fnReLaunchAll()}/>
      <div className={'columns-padding-no cont-obra-height'} onMouseOver={mouseOver} onMouseLeave={mouseLeaver}>
        <GlobalStyle/>
        <GlobalStylePage/>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavTop toPage={arNav.top}/>}
            </ContArrow>
          </Column>
        </Columns>

        <Columns className="columns-responsive columns-obra-height flex-center" isMultiline={false} isCentered={true}>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavLeft toPage={arNav.left}/>}
            </ContArrow>
          </Column>

          <Column isSize={{mobile: 10, tablet: 10, desktop: 10, widescreen: 10}} className={'flex-center container-animated'}>
            {/*<Img fixed={data.file.childImageSharp.fixed} />*/}
            <Img className={'img-obra'} fluid={data.placeholderImage.childImageSharp.fluid}/>
            {fnAnimateHombreVolando()}
            {fnAnimateMujer2()}
            {fnAnimateTorzo()}
            {fnAnimateMujerBailando()}
            {fnAnimatePiernas()}
            {fnAnimateHombreDeCabeza()}
            {fnSpeechBubbleMini()}
            {fnSpeechBubble()}
          </Column>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavRight toPage={arNav.right}/>}
            </ContArrow>
          </Column>

        </Columns>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavBottom toPage={arNav.bottom}/>}
            </ContArrow>
          </Column>
        </Columns>
      </div>
    </ContObraComp>
  );
};

export default Page05Comp;
